import * as React from "react"
// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  //maxWidth: 320,
}
const headingAccentStyles = {
  color: "#663399",
}
const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}
const listStyles = {
  marginBottom: 96,
  paddingLeft: 0,
}
const listItemStyles = {
  fontWeight: "300",
  fontSize: "24px",
  color: "#8954A8"
  //maxWidth: "560px",
}

const linkStyle = {
  //color: "#8954A8",
  fontWeight: "bold",
  fontSize: "16px",
  verticalAlign: "5%",
}

const profileLinkStyle = {
  ...linkStyle,
  listStyleType: "none",
  marginBottom: 24,
}

const descriptionStyle = {
  color: "#232129",
  fontSize: "14px",
}

const profileLink = {
  text: "Profile",
  url: "https://mexii.one",
  color: "#8954A8",
}
// data
const links = [
  //{
  //  text: "Tutorial",
  //  url: "https://www.gatsbyjs.com/docs/tutorial/",
  //  description:
  //    "A great place to get started if you're new to web development. Designed to guide you through setting up your first Gatsby site.",
  //  color: "#E95800",
  //},
  //{
  //  text: "How to Guides",
  //  url: "https://www.gatsbyjs.com/docs/how-to/",
  //  description:
  //    "Practical step-by-step guides to help you achieve a specific goal. Most useful when you're trying to get something done.",
  //  color: "#1099A8",
  //},
  //{
  //  text: "Reference Guides",
  //  url: "https://www.gatsbyjs.com/docs/reference/",
  //  description:
  //    "Nitty-gritty technical descriptions of how Gatsby works. Most useful when you need detailed information about Gatsby's APIs.",
  //  color: "#BC027F",
  //},
  //{
  //  text: "Conceptual Guides",
  //  url: "https://www.gatsbyjs.com/docs/conceptual/",
  //  description:
  //    "Big-picture explanations of higher-level Gatsby concepts. Most useful for building understanding of a particular topic.",
  //  color: "#0D96F2",
  //},
  //{
  //  text: "Plugin Library",
  //  url: "https://www.gatsbyjs.com/plugins",
  //  description:
  //    "Add functionality and customize your Gatsby site or app with thousands of plugins built by our amazing developer community.",
  //  color: "#000000",
  //},
  {
    text: "GitHub",
    url: "https://github.com/weremexii",
    description: "Welcome to star",
    color: "#000000"
  },
  {
    text: "Blog",
    url: "https://blog.mexii.one",
    description: "To see what I am doing now",
    color: "#5454f8"
  },
  {
    text: "Twitter",
    url: "https://twitter.com/weremexii",
    description: "Follow each other?",
    color: "#0D96F2",
  }
]

// markup
const IndexPage = () => {
  return (
    
    <main style={pageStyles}>
      <title>Mexii.one</title>
      
      <h1 style={headingStyles}>
        Mexii's Home
        <br />
        <span style={headingAccentStyles}>— to find where we can go</span>
        
      </h1>
      <p style={paragraphStyles}>
        <code style={codeStyles}>cat ~/available_destination</code>{" "}
      </p>
      
    
      <ul style={listStyles}>
        <li style={profileLinkStyle}>
          <a
            style={linkStyle}
            href={`${profileLink.url}`}
          >
            {profileLink.text}
          </a>
        </li>
        {links.map(link => (
          <li style={listItemStyles}>
            <span>
              <a
                style={{...linkStyle, color: link.color}}
                href={`${link.url}`}
              >
                {link.text}
              </a>
              <p style={descriptionStyle}>{link.description}</p>
            </span>
          </li>
        ))}
      </ul>
      
    </main>
  )
}

export default IndexPage

